<template>
  <div v-if="!authStore.isAuthenticated || (authStore.isAuthenticated && authStore.account && shouldHaveInstanceID)" class="w-full flex flex-col sm:!flex-row">
    <vs-omni-search
      v-model="isSearching"
      :results="searchResults"
      @search:changed="runSearch"
      @search:select="$router.push($event.path)"
    />
    <vs-instance-picker
      v-model="showInstancePicker"
      :instances="authStore.instances"
      :currentInstanceID="authStore.instanceID"
      @instance:selected="authStore.setInstanceID($event)"
    />
    <vs-sidebar
      v-if="!authStore.toolbarHidden && authStore.isAdmin && !authStore.maintenanceModeIsOn"
      :pages="adminPages"
      :active-page="activePage"
      @page:select="handlePageSelect"
    >
      <template #header="{ isCollapsed, isSmall }">
        <div class="flex items-center w-full justify-center h-20 mb-2">
          <a href="/">
            <img src="@/assets/logo.svg" :width="isCollapsed && !isSmall ? 40 : 50" />
          </a>
        </div>
        <div v-if="authStore.instanceID">
          <div class="mb-5 flex justify-center w-full">
            <vs-avatar
              class="cursor-pointer hover:brightness-90"
              :name="currentInstance?.name"
              @click="showInstancePicker = true"
            />
          </div>
          <div
            class="mx-4 mb-2 bg-surface px-2 py-2 flex items-center rounded cursor-pointer transition"
            :class="authStore.gettingSearchKey ? 'opacity-50 cursor-wait' : ''"
            @click="!authStore.gettingSearchKey && (isSearching = true)"
          >
            <vs-icon class="opacity-50 mr-2" name="akar-icons:search" />
            <div v-if="!isCollapsed || isSmall">
              Buscar
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-center p-4">
            <vs-preloader color="surface" :size=36></vs-preloader>
          </div>
        </div>
      </template>
    </vs-sidebar>
    <div 
      class="overflow-y-auto sm:w-9/12 sm:min-w-150 w-full flex flex-col flex-grow pt-safe"
      :class="authStore.isAdmin ? 'h-[calc(100vh-46px-env(safe-area-inset-bottom))] sm:h-screen' : 'h-screen'"  
    >
      <Suspense>
        <div
          class="relative flex-grow flex-shrink overflow-auto"
          :class="authStore.roles.includes('user') && !authStore.isAdmin ? 'sm:pb-0' : ''"
        >
          <router-view
            :key="getRouteKey($route.path)"
            v-slot="{ Component }"
          >
            <transition 
              :name="route.meta.transitionName"
              mode="out-in"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </Suspense>
      <div v-if="uiStore.map.teleportTo && !os">
        <Teleport
          :to="uiStore.map.teleportTo"
        >
          <vs-map
            :ref="setMapRef"
            v-bind="uiStore.map.attrs"
            v-if="!os"
            :locationPermission="false"
            @location:confirm="uiStore.map.attrs['v-on:location:confirm']"
            @ready="uiStore.setReady($event)"
            @click="handleMapClick"
            @marker:drag="handleMarkerDrag"
            @marker:infoClick="handleInfoClick"
            @marker:click="handleMarkerClick"
          />
        </Teleport>
      </div>
      <vs-toolbar
        v-if="!authStore.toolbarHidden &&  authStore.roles.includes('user') && !authStore.isAdmin && !authStore.maintenanceModeIsOn"
        :pages="userPages"
        @page:select="handlePageSelect"
        :active-page="activePage"
        :compact="true"
      ></vs-toolbar>
    </div>
  </div>
  <div v-else class="mt-20 flex flex-col justify-center items-center">
    <vs-preloader :size="32"/>
    <div>{{authStore.user?.phoneNumber || authStore.user?.email}}</div>
  </div>
  <modals-container></modals-container>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onBeforeMount, onMounted, computed, inject, Ref} from 'vue'
import { storeToRefs } from 'pinia'
import { firebaseTools } from '@/services/firebase'
import { isSupported } from '@firebase/messaging'
import { useAuthStore, useUIStore } from '@/stores'
import { useRouter, useRoute } from 'vue-router'
import { getAccount } from '@/services/queries'
import methods from '@/utils/methods'
import { client, renovateClient } from '@/services/typesense'
import { search } from "ss-search"
import { gql, useClientHandle } from '@urql/vue'
import { state } from '@/services/localStorage'
import { useEventBus } from '@vueuse/core'
import { vsOmniSearch, vsInstancePicker, vsSidebar, vsAvatar, vsIcon, vsMap, vsToolbar, vsPreloader } from '@isagaco/vuesaga'
type roles = 'no-roles'|'user'|'staff'|'instanceAdmin'|'support' 

window.googleApiLoaded = (e)=>{
  window.googleApi = true
}

export default defineComponent({
  name: 'App',
  components: {
    vsOmniSearch, vsInstancePicker, vsSidebar, vsAvatar, vsIcon, vsMap, vsToolbar, vsPreloader
  },
  setup (props, {expose}) {
    const authStore = useAuthStore()
    const uiStore = useUIStore()
    const clientHandle = useClientHandle()
    const $crisp = window.$crisp
    $crisp.push(["safe", true])
    const os = computed(() => {
      return window.os
    })
    const instances = computed(() => {
      return authStore.userInstances?.map(instance => instance.name)
    })
    const currentInstance = computed(() => {
      return authStore.instances.find(instance => {
        return instance.id === authStore.instanceID
      })
    })
    const parcels = computed(() => {
      return authStore.parcels?.map(parcel => {
        let str = `${parcel.name}: \n•${parcel.instance.name}\n`
        if (parcel.routes) {
          str += parcel.routes?.map(route=> '○' + route.name).join('\n')
        }
        return str
      }).join('\n\n')
    })
    watch(instances, () => {
      if (instances.value.length > 0) {
        $crisp.push(['set', 'session:segments', [instances.value]])
      }
    })
    watch(parcels, () => {
      $crisp.push(['set', 'session:data', [[['Pasajeros', parcels.value]]]])
    })
    const hideCrisp = () => {
      $crisp.push(['do', 'chat:hide'])
    }
    const setCrispToken = () => {
      const token = $crisp.get('session:identifier')
      if (token) setToken(token, 'crisp')
    }
    const setCrisp = () => {
      // TODO: this should be done in chat view, also inject javascript there
      window.CRISP_TOKEN_ID = authStore.account?.id
      $crisp.push(['do', 'session:reset'])
      $crisp.push(['set', 'user:nickname', authStore.account?.name])
      $crisp.push(['set', 'user:email', authStore.account?.email])
      $crisp.push(['set', 'user:phone', authStore.account?.phone])
      $crisp.push(['on', 'session:loaded', setCrispToken])
      $crisp.push(['on', 'chat:initiated', setCrispToken])
      $crisp.push(['on', 'chat:closed', hideCrisp])
      hideCrisp()
    }
    const $vfm:any = inject('$vfm')
    onMounted(async () => {
      // uiStore.setMapTeleport('#mapTeleport')
      // TODO: this should be done in the the vs-address component pass token and use there
      if(!window.googleApi) {
        let places = document.createElement('script')
        places.setAttribute('type', 'text/javascript')
        places.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDDtmcTqrihzaWY6BPyaZDKKu7jQm51Gbg&libraries=places&callback=googleApiLoaded')
        places.async = true
        document.getElementsByTagName('head')[0].appendChild(places)
      }
    })
    const notificationListener: Ref<any> = ref(null)
    watch(() => state.value.jwt, async (val, old) => {
      const currentUser = await firebaseTools.getCurrentUser()
      if (currentUser) {
        await authStore.setUser(currentUser)
        const customClaims = await firebaseTools.getCustomClaims(currentUser)
        if (typeof customClaims === 'undefined' || customClaims.version !== '2') return // wait for custom claims
        const role = customClaims['x-hasura-default-role'] as roles
        await authStore.setUser(currentUser)
        if (authStore.account === null) {
          const {data, error} = await clientHandle.client.query(getAccount(role), { firebaseID: currentUser.uid }).toPromise()
          if (error) {
            console.error(error)
            // TODO: retry on error after x seconds
            return
          }
          if (data?.users) {
            const user = data.users[0]
            await authStore.setAccount(user, customClaims)
            if (URLInstanceID.value) await authStore.setInstanceID(URLInstanceID.value as string)
            setCrisp()
            // if (await isSupported() && !window.os) {
            //   const permission = await Notification.requestPermission()
            //   if(permission === 'granted') {
            //     const token = await firebaseTools.getMessagingToken()
            //     if (token) {
            //       await setToken(token, 'firebase')
            //       if (!notificationListener.value) {
            //         // console.info('setting notification listener')
            //         notificationListener.value = firebaseTools.onNotificationListener((payload:any)=>{
            //           // console.info('notification Listener', payload)
            //           authStore.setLastPushTimestamp(Date.now())
            //           // console.info(authStore.lastPushTimestamp)
            //           createToast(withProps(vsToast, { type: payload.data.type || 'info', text: payload.data.body || ''}), {timeout: -1})
            //         })
            //       }
            //     }
            //   }
            // }
            authStore.setParcelData(data.users[0].parcels || [])
            authStore.setFavoriteRoutes(data.users[0]?.favorite_routes || [])
            await updateLastSeen()
            if (fcmToken.value) {
              setToken(fcmToken.value, 'fcm')
            }
            if (!fcmToken.value && window.os) {
              await authStore.getNotificationsToken()
            }
            const refURL = route.query.refURL
            if (route.path === '/login') {
              router.push(`${refURL ? refURL : '/'}`)
            }
          }
        }
      }
    }, {deep: true})
    const setViewHeight = () => {
      let vh = window.innerHeight
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    onBeforeMount(async () => {
      setViewHeight()
      window.addEventListener('resize', () => {
        setViewHeight()
      })
      const user = await firebaseTools.getCurrentUser()
      if (user && !!state.value.jwt && !!state.value.customClaimsUpdate) {
        // console.info('ther is user', user)
        await authStore.setUser(user)
        const customClaims = await firebaseTools.getCustomClaims(user)
        if (typeof customClaims === 'undefined' || customClaims.version !== '2') return // wait for custom claims
        const role = customClaims['x-hasura-default-role'] as roles
        await authStore.setUser(user)
        if (authStore.account === null) {
          const {data, error} = await clientHandle.client.query(getAccount(role), { firebaseID: user.uid }).toPromise()
          if (error) {
            console.error(error)
          }
          if (data?.users) {
            const user = data.users[0]
            await authStore.setAccount(user, customClaims)
            if (URLInstanceID.value) await authStore.setInstanceID(URLInstanceID.value as string)
            setCrisp()
            authStore.setParcelData(user?.parcels || [])
            authStore.setFavoriteRoutes(user?.favorite_routes || [])
            await updateLastSeen()
            if (fcmToken.value) {
              setToken(fcmToken.value, 'fcm')
            }
            if (!fcmToken.value && window.os) {
              await authStore.getNotificationsToken()
            }
            // if (await isSupported() && !window.os){
            //   const permission = await Notification.requestPermission()
            //   if(permission === 'granted') {
            //     const token = await firebaseTools.getMessagingToken()
            //     if (token) {
            //       await setToken(token, 'firebase')
            //       // firebaseTools.onNotifcationListener((payload:any)=>{
            //       //   console.info('listener', payload)
            //       // })
            //     }
            //   }
            // }
            // if ((!authStore.account.name || !authStore.account.phone) && !route.path.includes('/enrollment')) {
            //   router.push({ path: '/account/', hash: '#onBoarding'})
            // }
          }
        }
      }
    })
    const { fcmToken } = storeToRefs(authStore)
    watch(fcmToken, ()=>{
      if(fcmToken.value && !!state.value.jwt) {
        setToken(fcmToken.value, 'fcm')
      }
    })
    const router = useRouter()
    const route = useRoute()

    if (typeof universalLinks !== 'undefined') {
      universalLinks.subscribe(null, function (eventData) {
        if (eventData.url !== location.href) {
          console.info({
            path: eventData.path,
            query: eventData.params,
          })
          router.push({
            path: eventData.path,
            query: eventData.params,
          })
        }
        console.log('Did launch application from the link: ' + eventData.url);
      });
    }

    const instanceID = computed(() => {
      return authStore.instanceID
    })
    const URLInstanceID = computed(() => {
      return route.params.instanceID
    })
    watch(URLInstanceID, async () => {
      if (!URLInstanceID.value || URLInstanceID.value === 'undefined') {
        return
      }
      if (authStore.account && URLInstanceID.value) {
        await authStore.setInstanceID(URLInstanceID.value)  
      }
    }, {immediate: true})
    const unreads = ref()
    watch(instanceID, async (after, before) => {
      if (!before) {
        router.push({ name: route.name, params: { ...route.params }, query: route.query })
        return
      }
      router.push({ path: route.path.replace(before, after) })
    })
    const userInstancesWithPayments = computed(() => {
      return authStore.userInstances?.filter((instance: any) => {
          return instance.attributes.enabledPayments
        }) || []
    })
    const userInstancesWithoutHiddenDirectory = computed(() => {
      return authStore.userInstances?.filter((instance: any) => {
          return !instance.attributes.hideDirectory
        }) || []
    })
    const userPages = computed(()=> {
      return [
        {
          name: 'Inicio',
          icon: 'bxs:home-alt-2',
          path: '/user/map',
          id: 'user'
        },
        {
          name: 'Notificaciones',
          icon: 'ion:notifications-sharp',
          path: '/user/inbox/',
          id: 'inbox'
        },
        // {
        //   name: 'Reservas',
        //   icon: 'material-symbols-light:today',
        //   path: '/user/orders/',
        //   id: 'orders'
        // },
        ...(userInstancesWithoutHiddenDirectory.value.length > 0 ? [{
            name: 'Directorio',
            icon: 'material-symbols:headset-mic-outline',
            path: '/user/directory/',
            id: 'directory',
          },] : []),
        ...(userInstancesWithPayments.value.length > 0 ? [{
          name: 'Pagos',
          icon: 'ph:credit-card-bold',
          path: '/user/payments/',
          id: 'payments'
        }] : []),
        {
          name: 'Cuenta',
          icon: 'ph:user-circle-bold',
          path: '/account/',
          id: 'account'
        },
      ]
    })
    const adminPages = computed(() => {
      if (authStore.account !== null && authStore.instanceID) {
        return [
          ...(authStore.isSupport ? [{
            name: 'Expertos',
            icon: 'ph:crown-bold',
            path: `/supportDashboard/`,
            id: 'supportDashboard',
            pinned: true,
          }] : []),
          // ...(authStore.isSupport ? [{
          //   name: 'Vision artificial',
          //   icon: 'ph:eye-bold',
          //   path: `/ai/`,
          //   id: 'ai',
          // }] : []),
          {
            name: 'Tablero',
            icon: 'ph:presentation-chart-bold',
            path: `/admin/${instanceID.value}/dashboard/`,
            id: 'dashboard',
            pinned: true,
          },
          {
            name: 'Datos',
            icon: 'ph:database-bold',
            id: 'data',
            group: true,
            pages: [
              {
                name: 'Rutas fijas',
                id: 'routes',
                icon: 'ph:path-bold',
                path: `/admin/${instanceID.value}/data/routes`,
              },
              {
                name: 'Pasajeros',
                id: 'parcels',
                icon: 'ph:person',
                path: `/admin/${instanceID.value}/data/parcels`,
              },
              {
                name: 'Vehículos',
                id: 'vehicles',
                icon: 'ph:bus',
                path: `/admin/${instanceID.value}/data/vehicles`,
              },
              {
                name: 'Personal',
                id: 'staff',
                icon: 'ph:users-three-bold',
                path: `/admin/${instanceID.value}/data/staff`,
              },
              {
                name: 'Lugares',
                id: 'stops',
                icon: 'ph:map-pin',
                path: `/admin/${instanceID.value}/data/stops`,
              },
              {
                name: 'Clientes',
                id: 'clients',
                icon: 'streamline:information-desk-customer',
                path: `/admin/${instanceID.value}/data/clients`,
              },
            ]
          },
          {
            name: 'Recaudos',
            id: 'charges',
            icon: 'solar:money-bag-outline',
            path: `/admin/${instanceID.value}/charges`,
          },
          // {
          //   name: 'Paradas',
          //   id: 'stops',
          //   icon: 'mdi:map-marker',
          //   path: `/admin/${instanceID.value}/stopViewer/`,
          // },
          {
            name: 'Novedades',
            id: 'temporals',
            icon: 'ph:arrows-clockwise-bold',
            path: `/admin/${instanceID.value}/temporals/`,
          },
          {
            name: 'Planeador',
            icon: 'tdesign:map-route-planning',
            id: 'data',
            group: true,
            pages: [
              {
                name: 'Rutas fijas',
                id: 'plans',
                icon: 'material-symbols:calendar-month',
                path: `/admin/${instanceID.value}/plans/1`,
              },
              {
                name: 'Servicios diarios',
                id: 'plans',
                icon: 'material-symbols-light:today',
                path: `/admin/${instanceID.value}/trips/${new Date().toISOString().split('T')[0]}`,
              },
            ]
          },
          // {
          //   name: 'Planeador',
          //   id: 'plans',
          //   icon: 'material-symbols:calendar-month',
          //   path: `/admin/${instanceID.value}/plans/1`,
          // },
          {
            name: 'Mapa de operación',
            id: 'viewer',
            icon: 'material-symbols:map-outline-rounded',
            path: `/admin/${instanceID.value}/viewer/`,
          },
          // ...(!authStore.isReadOnly ? [{
          //   name: 'Optimización',
          //   icon: 'ph:rocket-bold',
          //   path: `/admin/${instanceID.value}/optimization/`,
          //   id: 'optimization'
          // }] : []),
          ...(!authStore.isReadOnly ? [{
            name: 'Optimizador',
            icon: 'ph:rocket-bold',
            id: 'data',
            group: true,
            pages: [
              {
                name: 'Rutas fijas',
                id: 'fixedPlans',
                icon: 'material-symbols:calendar-month',
                path: `/admin/${instanceID.value}/classicOptimization/`,
              },
              {
                name: 'Servicios diarios',
                id: 'plans',
                icon: 'tdesign:map-route-planning',
                path: `/admin/${instanceID.value}/optimization/`,
              },
            ]
          }] : []),
          ...(!authStore.isReadOnly ? [{
            name: 'Directorio',
            icon: 'material-symbols:headset-mic-outline',
            path: `/admin/${instanceID.value}/directory/`,
            id: 'directory',
            alert: !!unreads.value
          }] : []),
          {
            name: 'Comunicados',
            icon: 'ph:megaphone-bold',
            path: `/admin/${instanceID.value}/notifications/`,
            id: 'notifications',
            pinned: true
          },
          {
            name: 'Histórico',
            icon: 'ph:clock-bold',
            path: `/admin/${instanceID.value}/history/`,
            id: 'history',
            pinned: true,
          },
          {
            name: 'Torre de control',
            icon: 'ph:globe-hemisphere-west-bold',
            path: `/admin/${instanceID.value}/tower/`,
            id: 'tower',
            pinned: true,
          },
          {
            name: 'Configuración',
            icon: 'ph:gear-bold',
            id: 'config',
            group: true,
            pages: [
            {
                name: 'Contrato',
                icon: 'ph:file-cloud',
                path: `/admin/${instanceID.value}/contract/`,
                id: 'contract'
              },
              {
                name: 'Roles',
                icon: 'ph:users-bold',
                path: `/admin/${instanceID.value}/roles/`,
                id: 'roles'
              },
              {
                name: 'Integraciones',
                icon: 'ph:plugs-bold',
                path: `/admin/${instanceID.value}/integrations/`,
                id: 'integrations'
              },
              {
                name: 'Cuenta',
                icon: 'ph:user-circle-bold',
                path: '/account/',
                id: 'account'
              },
            ]
          },
        ]
      } else {
        return []
      }
    }) as any
    const activePage = ref('')
    watch(route, () => {
      activePage.value = (route.path as string) || ''
      uiStore.setMapAttrs({})
    })
    const handlePageSelect = (page: Record<string, string>) => {
      if (page.group) {
        if (page.pages) {
          router.push(page.pages[0]?.path)
        }
      }
      if (!page.path) return
      router.push(page.path)
    }
    const setToken = async (token, type) => {
      await methods.mutation([{
        action: 'insert',
        table: 'tokens',
        data: {
          userID: authStore.account?.id,
          token,
          type
        }
      }], null, null, true)
      // console.info(response)
    }
    const updateLastSeen = async () => {
      if (authStore.account) {
        await methods.mutation([{
          action: 'update',
          table: 'users',
          pkey: `id: "${authStore.account.id}"`,
          data: {
            lastSeen: new Date(),
          },
          returnKeys: 'id'
        }], null, null, true)
      }
    }
    const isSearching = ref(false)
    const searchHasErrors = ref(false)
    const lastSearch = ref('')
    const showInstancePicker = ref(false)
    const searchResults = ref()
    const runSearch = async (e) => {
      lastSearch.value = e
      if (!e) {
        searchResults.value = null
        return
      }
      const searchRequests = {
        searches: [
          {
            collection: 'parcels',
            query_by: 'name,documentID'
          },
          {
            collection: 'routes',
            query_by: 'name'
          },
          {
            collection: 'vehicles',
            query_by: 'name'
          },
          {
            collection: 'staff',
            query_by: 'name,phone'
          },
          {
            collection: 'stops',
            query_by: 'name,place,address'
          },
          {
            collection: 'clients',
            query_by: 'name,documentID'
          },
        ]
      }
      const commonSearchParams =  {
        q: e,
        prioritize_exact_match: true,
      }
      let searchResult = null
      try {
        if (searchHasErrors.value) return
        if (state.value.searchKey === '') throw new Error('Forbidden - a valid `x-typesense-api-key` header must be sent')
        searchResult = await client().multiSearch.perform(searchRequests, commonSearchParams)
      } catch (error) {
        if (((error as Error).message).includes('Forbidden')) {
          searchHasErrors.value = true
          await renovateClient(authStore.instanceID)
          searchHasErrors.value = false
          searchResult = await client().multiSearch.perform(searchRequests, {...commonSearchParams, q: lastSearch.value})
        }
      }
      const tables = [
        {
          name:'Pasajeros',
          id: 'parcels',
        },
        {
          name:'Rutas',
          id: 'routes',
        },
        {
          name:'Vehículos',
          id: 'vehicles',
        },
        {
          name:'Personal',
          id: 'staff',
        },
        {
          name:'Lugares',
          id: 'stops',
        },
        {
          name:'Clientes',
          id: 'clients',
        },
      ]
      const routerResults = search(router.options.routes, ['name'], e)
      searchResults.value = [
        ...(await Promise.all(searchResult.results.map(async (result, index) => {
          let parcelAvatars = null
          if (tables[index].name === 'Pasajeros') {
            const parcelIDs = result.hits?.map(hit => `"${hit.document.id}"`)
            const query = gql`
              query getParcelsForOmniSearch {
                parcels(where: {id: {_in: [${parcelIDs}]}}) {
                  id
                  avatar
                }
              }
            `
            const {data, error} = await clientHandle.client.query(query, {}).toPromise()
            if (error) console.error(error)
            parcelAvatars = data.parcels
          }
          return {
            name: tables[index].name,
            avatar: !index,
            items: result?.hits?.map(hit => {
              const document = hit.document
              return {
                avatar: parcelAvatars?.find(parcel => parcel.id === document.id)?.avatar,
                name: document.name,
                footer: document.documentID || document.phone || document.address,
                mark: hit.highlights[0]?.snippet.split('>')[1].split('<')[0],
                id: document.id,
                path: (tables[index].name !== 'Lugares') ? `/admin/${instanceID.value}/data/${tables[index].id}/${document.id}` : `/admin/${instanceID.value}/addressEditor/null/${document.id}`,
              }
            })
          }
        }))),
        {
          name: 'Navegación',
          avatar: false,
          items: routerResults.map(result => {
            return {
              name: result.name,
              mark: e,
              path: result.path,
              icon: 'akar-icons:link-chain',
            }
          })
        }
      ]
    }
    const { emit: mapClickEmit } = useEventBus<string>('map:click')
    const handleMapClick = (e) => {
      mapClickEmit(e)
    }
    const { emit: markerDragEmit } = useEventBus<string>('marker:drag')
    const handleMarkerDrag = (e) => {
      markerDragEmit(e)
    }
    const { emit: infoClickEmit } = useEventBus<string>('marker:infoClick')
    const handleInfoClick = (e) => {
      infoClickEmit(e)
    }
    const { emit: markerClickEmit } = useEventBus<string>('marker:click')
    const handleMarkerClick = (e) => {
      markerClickEmit(e)
    }
    const getRouteKey = (path:string) => {
      if (path.includes('dashboard')) {
        return 'dashboard';
      }else{
        return path;
      }
    }
    const mapRef = ref(null)
    const setMapRef = (el) => {
      mapRef.value = el
    }
    expose({
      getMap: () => mapRef.value.getMap()
    })
    const shouldHaveInstanceID = computed(() => {
      if (route.path.includes('enrollment')) return true
      if (!!authStore.isAuthenticated && !!authStore.account && !!authStore.isAdmin) {
        return !!authStore.instanceID
      }
      return true
    })
    return {
      shouldHaveInstanceID,
      setMapRef,
      mapRef,
      uiStore,
      route,
      currentInstance,
      userPages,
      adminPages,
      activePage,
      handlePageSelect,
      authStore,
      isSearching,
      showInstancePicker,
      searchResults,
      runSearch,
      handleMapClick,
      handleMarkerDrag,
      handleInfoClick,
      handleMarkerClick,
      os,
      getRouteKey
    }
  }
})
</script>

<style scoped>
.slide-left-enter-active, .slide-left-leave-active {
  transition: all 0.3s ease;
}

.slide-left-enter-from, .slide-left-leave-active {
  transform: translateX(-2em);
  opacity: 0;
}

.slide-right-enter-active, .slide-right-leave-active {
  transition: all 0.3s ease;
}

.slide-right-enter-from, .slide-right-leave-active {
  transform: translateX(2em);
  opacity: 0;
}
</style>